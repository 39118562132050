import EventService from "service/event/EventService";
import Month from "../constants/DaMonths";
// import APIService from "service/network/APIService";
class Helper {
  constructor() {
    // this.apiService = new APIService();
    this._initializeNaturalExtensions();
  }

  _initializeNaturalExtensions() {
    if (!String.prototype.format) {
      String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
          return typeof args[number] !== "undefined" ? args[number] : match;
        });
      };
    }
  }

  isJSON(obj) {
    try {
      JSON.parse(obj);
      return true;
    } catch (error) {
      return false;
    }
  }

  startLoading() {
    EventService.emit("startLoader", {});
  }

  stopLoading() {
    EventService.emit("stopLoader", {});
  }

  navigateTo(props, to) {
    if (to == 1) {
      window.dispatchEvent(new Event("openVacPoster"));
    } else if (!to.startsWith("http")) {
      props.history.replace(to);
    } else {
      window.open(to, "_blank");
    }
  }

  findById(arr, id) {
    return (
      arr.filter((obj) => {
        return obj.id === id;
      })[0] || {}
    );
  }

  findByIdRecursive(arr, childrenKey, id) {
    let matchingObject = this.findById(arr, id);

    if (this.isEmptyObject(matchingObject)) {
      arr.forEach((obj) => {
        let matchingObjectRecursive = this.findByIdRecursive(
          obj[childrenKey],
          childrenKey,
          id
        );
        if (!this.isEmptyObject(matchingObjectRecursive)) {
          matchingObject = matchingObjectRecursive;
        }
      });
    }

    return matchingObject;
  }

  findIndexByKey(arr, key, value) {
    let returnIndex = -1;

    arr.filter((obj, index) => {
      if (obj[key] === value) {
        returnIndex = index;
        return true;
      }

      return null;
    });

    return returnIndex;
  }

  findObjectByKeyValue(arr, key, value) {
    return (
      arr.filter((obj, index) => {
        return obj[key] === value;
      })[0] || {}
    );
  }

  filterByKeyValue(arr, key, value) {
    return (
      arr.filter((obj, index) => {
        return obj[key] === value;
      }) || []
    );
  }

  findKeyByValue(obj, value) {
    let returnKey = null;
    for (let key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }

    return returnKey;
  }

  arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
  }

  isEmptyObject(obj) {
    if (obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    return false;
  }

  createMarkup(htmlString) {
    return { __html: htmlString };
  }

  enumToArray(obj) {
    let returnArray = [];
    for (let key in obj) {
      returnArray.push({
        name: key,
        value: obj[key],
      });
    }

    return returnArray;
  }

  unionArrays(arrayOne, arrayTwo, equalityFunction) {
    let union = arrayOne.concat(arrayTwo);

    for (let i = 0; i < union.length; i++) {
      for (let j = i + 1; j < union.length; j++) {
        if (equalityFunction(union[i], union[j])) {
          union.splice(j, 1);
          j--;
        }
      }
    }

    return union;
  }

  intersectArrays(arrayOne, arrayTwo, equalityFunction) {
    if (arrayOne.length === 0) {
      return arrayTwo;
    }

    if (arrayTwo.length === 0) {
      return arrayOne;
    }

    let temp;
    if (arrayTwo.length > arrayOne.length) {
      temp = arrayTwo;
      arrayTwo = arrayOne;
      arrayOne = temp; // indexOf to loop over shorter
    }
    return arrayOne.filter(function (e) {
      return arrayTwo.indexOf(e) > -1;
    });
  }

  copy(o) {
    let that = this;
    let output, v, key;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
      v = o[key];
      output[key] = typeof v === "object" ? that.copy(v) : v;
    }
    return output;
  }

  isInAllowedRoles(allowedRoles, roles) {
    let isInAllowedRoles = false;

    try {
      roles.forEach((role) => {
        if (allowedRoles.indexOf(role.name) > -1) {
          isInAllowedRoles = true;
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }

    return isInAllowedRoles;
  }

  findMonth(value) {
    if (value) {
      var a = value.toString();
      if (a.length < 2) {
        a = "0" + value;
      }
      return Month.find((e) => {
        return e.value === a;
      }).label;
    } else return "";
  }
  scrollIntoView = (id) => {
    let div = document.getElementById(id);
    if (div)
      div.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  };
  returnStatus(list, status) {
    var result = [];
    if (list && list !== undefined && list.length > 0) {
      list.map((item) => {
        var disItem = {
          label: item.label,
          value: item.value,
          disabled: item.status !== status,
        };

        result.push(disItem);
      });
    }
    return result;
  }
}

export default Helper;
