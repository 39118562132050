import EnvironmentService from "service/environment/EnvironmentService";

let environmentService = new EnvironmentService();
let baseAPI = environmentService.getBaseAPI();
let serviceAPI = environmentService.getServiceAPI();
let serviceSocket = environmentService.getServiceSocket();
let serviceAPINew = environmentService.getServiceAPINew();
let strapiAPI = environmentService.getStrapiAPI();
const APIConfig = {
  base: baseAPI,
  microService: serviceAPI,

  tenant: {
    getTenant: serviceAPI + "/v2/tenant",
  },

  user: {
    // getOTP: baseAPI + "/employees/otp/get",
    // verifyOTP: baseAPI + "/employees/otp/verify",
    // getDoctorOTP: baseAPI + "/doclogin/otp/get",
    // verifyDoctorOTP: baseAPI + "/doclogin/otp/verify",
    getOTP: baseAPI + "/employees/otp/v3/get",
    verifyOTP: baseAPI + "/employees/otp/v3/verify",
    getDoctorOTP: baseAPI + "/doclogin/otp/v3/get",
    verifyDoctorOTP: baseAPI + "/doclogin/otp/v3/verify",
    new: baseAPI + "/employees/new",
    getAll: baseAPI + "/employees/all",
    get: baseAPI + "/employees/{0}",
    verifyDocCode: baseAPI + "/docprofile/doctor?code={0}",
    deliveryDate: serviceAPI + "",
  },

  coupons: {
    get: serviceAPINew + "/api/v1/coupon/verify?phoneno={0}&code={1}",
    generate: serviceAPINew + "/api/v1/coupon/create",
  },

  doctor: {
    getAll: baseAPI + "/doctor/all",
    addNew: baseAPI + "/doctor/add",
    getAccountCode: serviceAPI + "/v1/doctors/account/code/{0}",
    update: baseAPI + "/doctor/",
    activate: baseAPI + "/doctor/update/status",
    deactivate: baseAPI + "/doctor/update/status",
    invite: baseAPI + "/invite",
    getInvite: baseAPI + "/doctor/patients/invites",
    doctorNotes: baseAPI + "/doctor/{0}/notes/{1}",
    patientCount: baseAPI + "/doctor/patients/count",
    newInvite: serviceAPINew + "/api/v1/sms/doctorinvite",
    newAccessToken: baseAPI + "/doclogin/token",
  },
  broadcaster: {
    fetchAllJobs: serviceAPI + "/api/v1/broadcaster/getByAll",
    newJob: serviceAPI + "/api/v1/broadcaster/create",
    fetchJobById: serviceAPI + "/api/v1/broadcaster/getById/{0}",
    updateJob: serviceAPI + "/api/v1/broadcaster/update/{0}",
  },
  patient: {
    new: baseAPI + "/patients/new",
    getAll: baseAPI + "/patients/all",
    get: baseAPI + "/patients/{0}",
    updateParams: baseAPI + "/patients/",
    updateParamGroups: baseAPI + "/patients/{0}/groups",
    getTrackingInfo: baseAPI + "/patients/{0}/track?doctorId={1}",
    updateStatus: baseAPI + "/patients/{0}/update/status",
    invite: baseAPI + "/patients/{0}/invite",
    updateEmail: baseAPI + "/patients/{0}/email",
    search: baseAPI + "/patients/search?q={0}&type={1}&paid={2}",
    searchFromDoctor:
      baseAPI + "/doctor/patients/search?q={0}&type={1}&from={2}&size={3}",
    searchParentsFromDoctor:
      baseAPI + "/doctor/patients/searchParents?q={0}&type={1}&from={2}",
    verifyStatus: baseAPI + "/patients/{0}/verify",
    prescription: baseAPI + "/patients/{0}/verify",
    searchPhoneno: baseAPI + "/patients/search?q={0}&type={1}&paid={2}&cc={3}",
    referrer: baseAPI + "/patients/{0}/referrer",
    primaryTherapy: serviceAPI + "/v2/pa/assessments/{0}/primary",
    sendNote: serviceAPI + "/v1/chat/users/patients/{0}/message",
    latestUserProfile: serviceAPI + "/v1/patients/{0}",
    deliveryDate: serviceAPI + "/v2/pa/assessments/deliverydate/{0}",
  },
  tracking: {
    chiefComplaint: {
      add: serviceAPI + "/api/v1/tracking/cc/{0}",
      get: serviceAPI + "/api/v1/tracking/cc/{0}/count",
      getNext: serviceAPI + "/api/v1/tracking/cc/{0}?pageSize={1}&pageNo={2}",
    },
    surgery: {
      add: serviceAPI + "/api/v1/tracking/surgery/{0}",
      get: serviceAPI + "/api/v1/tracking/surgery/{0}/count",
      getNext:
        serviceAPI + "/api/v1/tracking/surgery/{0}?pageSize={1}&pageNo={2}",
    },
    hospitalizations: {
      get: serviceAPI + "/api/v1/hospitalisation/{0}",
    },
    medicine: {
      add: serviceAPI + "/api/v1/tracking/md/{0}",
      get: serviceAPI + "/api/v1/tracking/md/{0}/count",
      getNext: serviceAPI + "/api/v1/tracking/md/{0}?pageSize={1}&pageNo={2}",
    },
    labtest: {
      add: serviceAPI + "/api/v1/tracking/li/{0}",
      // update: serviceAPI + "/api/v1/tracking/li/{0}/{1}",
      update: serviceAPI + "/api/v1/tracking/li/{0}",
      // add: serviceAPI + "/api/v1/tracking/li/{0}/latest/tracking",
      // update: serviceAPI + "/api/v1/tracking/li/{0}/latest/tracking",
      get: serviceAPI + "/api/v1/tracking/li/{0}/count",
      getNext: serviceAPI + "/api/v1/tracking/li/{0}?pageSize={1}&pageNo={2}",
      getLatest: serviceAPI + "/api/v1/tracking/li/{0}/latest/tracking",
    },
    diagnosis: {
      add: serviceAPI + "/api/v1/tracking/dh/{0}",
      get: serviceAPI + "/api/v1/tracking/dh/{0}/count",
      getNext: serviceAPI + "/api/v1/tracking/dh/{0}?pageSize={1}&pageNo={2}",
    },
    addictions: {
      add: serviceAPI + "/api/v1/tracking/addiction/{0}",
      get: serviceAPI + "/api/v1/tracking/addiction/{0}/count",
      getNext:
        serviceAPI + "/api/v1/tracking/addiction/{0}?pageSize={1}&pageNo={2}",
    },
  },

  feedback: {
    getAll: baseAPI + "/feedback/all",
  },

  unit: {
    new: baseAPI + "/units/new",
    getAll: baseAPI + "/units/all",
    get: baseAPI + "/units/",
  },

  param: {
    new: baseAPI + "/params/new",
    getAll: baseAPI + "/params/all",
    get: baseAPI + "/params/",
    update: baseAPI + "/params/{0}/update",
    group: {
      new: baseAPI + "/params/groups/new",
      getAll: baseAPI + "/params/groups/all",
      get: baseAPI + "/params/groups/{0}",
      update: baseAPI + "/params/groups/{0}",
    },
  },

  goal: {
    master: {
      new: baseAPI + "/goals/master/new",
      getAll: baseAPI + "/goals/master/all",
      get: baseAPI + "/goals/master/",
      update: baseAPI + "/goals/master/",
    },
    new: baseAPI + "/goals/new",
    getAll: baseAPI + "/goals/all",
    get: baseAPI + "/goals/",
    update: baseAPI + "/goals/",
  },

  task: {
    master: {
      new: baseAPI + "/tasks/master/new",
      getAll: baseAPI + "/tasks/master/all",
      get: baseAPI + "/tasks/master/",
      update: baseAPI + "/tasks/master/",
    },
    recurring: {
      new: baseAPI + "/tasks/recurring/new",
      update: baseAPI + "/tasks/recurring/",
    },
    new: baseAPI + "/tasks/new",
    getAll: baseAPI + "/tasks/all",
    get: baseAPI + "/tasks/",
    update: baseAPI + "/tasks/",
  },

  communication: {
    send: baseAPI + "/communication/send",
  },
  email: {
    send: serviceAPI + "/api/v1/email",
  },
  paramValue: {
    new: baseAPI + "/params/value/new",
    getAllByPatient: baseAPI + "/params/value/patients/{0}?dueDate={1}",
    get: baseAPI + "/params/",
    update: baseAPI + "/params/value/",
  },

  role: {
    new: baseAPI + "/roles/new",
    getAll: baseAPI + "/roles/all",
    update: baseAPI + "/roles/{0}/update",
    roleById: baseAPI + "/roles/{0}",
  },

  permission: {
    getAll: baseAPI + "/permissions/all",
  },

  feed: {
    newInformationCard: baseAPI + "/feeds/information/new",
    getInformationCards: baseAPI + "/feeds/information/all",
    getInformationCard: baseAPI + "/feeds/information/{0}",
    updateInformationCard: baseAPI + "/feeds/information/update/{0}",
  },

  checklistMaster: {
    new: baseAPI + "/checklists/master/new",
    get: baseAPI + "/checklists/master/{0}",
    getAll: baseAPI + "/checklists/master/all",
    update: baseAPI + "/checklists/master/{0}",
  },

  checklist: {
    new: baseAPI + "/checklists/new",
    get: baseAPI + "/checklists/{0}",
    getAll: baseAPI + "/checklists/all",
    update: baseAPI + "/checklists/{0}",
    getByPatientDoctor:
      baseAPI + "/checklists/patient-doctor?patientId={0}&doctorId={1}",
  },

  checklistValue: {
    new: baseAPI + "/checklists/values/new",
    get: baseAPI + "/checklists/values/{0}",
    getAll: baseAPI + "/checklists/values/all",
    update: baseAPI + "/checklists/values/{0}",
    getByPatientDoctor:
      baseAPI +
      "/checklists/values/patient-doctor?patientId={0}&doctorId={1}&dueDate={2}",
  },

  careTeam: {
    new: baseAPI + "/careteams/new",
    get: baseAPI + "/careteams/{0}",
    getAll: baseAPI + "/careteams/all",
    update: baseAPI + "/careteams/{0}",
    getByPatient: baseAPI + "/careteams/patients/{0}",
  },

  call: {
    new: baseAPI + "/calls/new",
    get: baseAPI + "/calls/{0}",
    getDoctorName: baseAPI + "/calls/doctor/{0}",
    getAll: baseAPI + "/calls/all",
    update: baseAPI + "/calls/{0}",
    getCallPlan: baseAPI + "/calls/callplan?caller={0}&callee={1}&dueDate={2}",
    getCallPlanByCallee:
      baseAPI + "/calls/callplan/callee?callee={0}&fromDate={1}&toDate={2}",
    getDoctorNamesAndDueDates:
      baseAPI +
      "/calls/all_callers_with_due_date?callee={0}&fromDate={1}&toDate={2}",
  },

  callNote: {
    new: baseAPI + "/calls/notes/new",
    get: baseAPI + "/calls/notes/{0}",
    getAll: baseAPI + "/calls/notes/all",
    update: baseAPI + "/calls/notes/{0}",
    disable: baseAPI + "/calls/notes/disable/{0}",
    getAllByCall: baseAPI + "/calls/notes/calls/{0}",
    detailedNote: baseAPI + "/calls/notes/details/{0}",
    directMessage: serviceAPI + "/v1/chat/users/patients/{0}/message",
    getUserId: serviceAPI + "/api/v1/user/patient/{0}",
  },

  callToDo: {
    new: baseAPI + "/calls/todos/new",
    get: baseAPI + "/calls/todos/{0}",
    getAll: baseAPI + "/calls/todos/all",
    update: baseAPI + "/calls/todos/{0}",
    getAllByCall: baseAPI + "/calls/todos/calls/{0}",
  },

  medication: {
    new: baseAPI + "/medications/new",
    get: baseAPI + "/medications/{0}",
    getAll: baseAPI + "/medications/all",
    update: baseAPI + "/medications/{0}",
  },

  chiefComplaint: {
    new: baseAPI + "/chief-complaints/new",
    get: baseAPI + "/chief-complaints/{0}",
    getAll: baseAPI + "/chief-complaints/all",
    update: baseAPI + "/chief-complaints/{0}",
  },

  reporting: {
    new: baseAPI + "/reportings/new",
    get: baseAPI + "/reportings/{0}",
    getAll: baseAPI + "/reportings/all",
    update: baseAPI + "/reportings/{0}",
    getByPatient: baseAPI + "/reportings/patients/{0}?dueDate={1}",
  },

  document: {
    new: baseAPI + "/documents/new",
    get: baseAPI + "/documents/{0}",
    getAll: baseAPI + "/documents/all",
    getAllFolders: baseAPI + "/documents/folders/all",
    update: baseAPI + "/documents/{0}",
    updateType: baseAPI + "/documents/{0}/update-type/{1}",
    updateNameAndDescription:
      baseAPI + "/documents/{0}/update-name-and-description",
    getByPatient: baseAPI + "/documents/patients/{0}?dueDate={1}",
  },
  documentApis: {
    getDoc: serviceAPI + "/v1/documents/{0}",
    getByPatientDoc: serviceAPI + "/v1/documents/patients/{0}?groupBy=type",
    uploadDoc: serviceAPI + "/v1/documents",
    getAllFolders: serviceAPI + "/v1/documents/folders",
    updateType: serviceAPI + "/v1/documents/{0}/type",
    updateNameAndDescription: serviceAPI + "/v1/documents/{0}/name",
    tokenGenerate: baseAPI + "/documents/token/generate",
  },

  cuisine: {
    new: baseAPI + "/cuisines/new",
    get: baseAPI + "/cuisines",
    getAll: baseAPI + "/cuisines/all",
    update: baseAPI + "/cuisines/",
  },

  medicalCondition: {
    new: baseAPI + "/medical_conditions/new",
    get: baseAPI + "/medical_conditions",
    getAll: baseAPI + "/medical_conditions/all",
    update: baseAPI + "/medical_conditions/",
  },

  constituent: {
    new: baseAPI + "/constituents/new",
    get: baseAPI + "/constituents",
    getAll: baseAPI + "/constituents/all",
    update: baseAPI + "/constituents/",
  },

  recipe: {
    new: baseAPI + "/recipes/new",
    get: baseAPI + "/recipes",
    getAll: baseAPI + "/recipes/all",
    update: baseAPI + "/recipes/",
  },

  dish: {
    master: {
      new: baseAPI + "/dish_master/new",
      getAll: baseAPI + "/dish_master/all",
      get: baseAPI + "/dish_master",
      update: baseAPI + "/dish_master/",
    },
    new: baseAPI + "/dishes/new",
    getAll: baseAPI + "/dishes/all",
    get: baseAPI + "/dishes",
    update: baseAPI + "/dishes/",
  },

  dishConstituent: {
    new: baseAPI + "/dish_constituents/new",
    get: baseAPI + "/dish_constituents",
    getAll: baseAPI + "/dish_constituents/all",
    update: baseAPI + "/dish_constituents/",
  },

  dishMedicalCondition: {
    new: baseAPI + "/dish_medical_conditions/new",
    get: baseAPI + "/dish_medical_conditions",
    getAll: baseAPI + "/dish_medical_conditions/all",
    update: baseAPI + "/dish_medical_conditions/",
  },

  salesPatient: {
    getAll: baseAPI + "/sales/patients/all?pageNumber={0}&pageSize={1}",
    updateProfile: baseAPI + "/sales/patients/{0}/profile",
  },

  onboardingDetail: {
    new: baseAPI + "/onboarding-details/new",
    get: baseAPI + "/onboarding-details/{0}",
    getAll: baseAPI + "/onboarding-details/all",
    update: baseAPI + "/onboarding-details/{0}",
  },

  testReading: {
    new: baseAPI + "/test-readings/new",
    update: baseAPI + "/test-readings/{0}",
    // updateRecommended: baseAPI + "/test-readings/update-recommended/{0}",
    updateRecommended: serviceAPI + "/api/v2/vitals/{0}/recommended",
    get: serviceAPI + "/api/v2/vitals/{0}?forDate={1}",
    getPatientsTestReadings: serviceAPI + "/api/v2/vitals/{0}/all?forDate={1}",
    updateVitals: serviceAPI + "/api/v2/vitals/{0}?forDate={1} ",
  },

  careManagerService: {
    get: serviceAPI + "/api/v1/chat/user/{0}/token",
    getAll: serviceAPI + "/api/v1/chat/users/all",
    getAllActivity: serviceAPI + "/api/v1/chat/user/activity",
    getAllForAsync: serviceAPI + "/api/v1/chat/ws/users",
    update: serviceAPI + "/api/v1/chat/cm/{0}/token/generate",
    patchupdate: serviceAPI + "/api/v1/user/{0}",
    deleteUser: serviceAPI + "/api/v1/chat/user/{0}/sm/continue",
  },
  careManagerSocket: {
    getAll: serviceSocket + "/api/v1/chat/users",
  },

  routineReporting: {
    getAll: serviceAPI + "/api/v1/report/{0}",
    getWithDateSpecified:
      serviceAPI + "/api/v1/report/data/{0}?date={1}&format=rtf",
  },

  meditation: {
    getAll: baseAPI + "/meditations/all",
    get: baseAPI + "/meditations/{0}",
  },

  progressNote: {
    new: serviceAPI + "/api/v1/progress_notes",
    getAll: serviceAPI + "/api/v1/progress_notes/{0}?interval={1}&number={2}",
    update: serviceAPI + "/api/v1/progress_notes/{0}",
  },

  nutritionNote: {
    new: serviceAPI + "/api/v1/nutrition_notes",
    getAll: serviceAPI + "/api/v1/nutrition_notes/{0}?interval={1}&number={2}",
    update: serviceAPI + "/api/v1/nutrition_notes/{0}",
  },

  detailedAssessment: {
    getDataTables: serviceAPI + "/api/v1/dt/referenceList/getAllDetails",
    getDataAssissment: serviceAPI + "/api/v1/da/{0}?userId={1}&userName={2}",
    update:
      serviceAPI + "/api/v1/da/{0}/update/{1}?isPDUpdated={2}&userName={3}",
    getIcd: serviceAPI + "/api/v1/dt/icd/search?queryString={0}",
    getSurgeries: serviceAPI + "/api/v1/dt/surgery/search?queryString={0}",
    getMedicines: serviceAPI + "/api/v1/dt/medicines/search?queryString={0}",
    getLabTest: serviceAPI + "/api/v1/dt/labtest/search?queryString={0}",
    getSubTest:
      serviceAPI + "/api/v1/dt/labtest/{0}/sublabtests/search?queryString={1}",
    getSubTestData:
      serviceAPI + "/api/v1/dt/labtest/{0}/sublabtests/onselect/ {1}",
    getSubTestData:
      serviceAPI + "/api/v1/dt/labtest/ {0}/sublabtests/onselect/{1}",
    fetchDAbyID: serviceAPI + "/api/v1/da/{0}/getById/{1}",
    fetchMedicineByCode: serviceAPI + "/api/v1/dt/medicines/getByCodes",
  },

  newProgressNote: {
    new: serviceAPI + "/api/v1/progress_notes",
    getAll:
      serviceAPI +
      '/api/v1/progress_notes?where={"patientId":{0}}&embedded={"comments":1}&max_results={1}&page={2}&sort=[("_id",-1)]',
    getAllWithType:
      serviceAPI +
      '/api/v1/progress_notes?where={"patientId":{0},"tag":{3}}&embedded={"comments":1}&max_results={1}&page={2}&sort=[("_id",-1)]',
    update: serviceAPI + "/api/v1/progress_notes/{0}",
  },

  progressNoteComment: {
    new: serviceAPI + "/api/v1/comments",
    update: serviceAPI + "/api/v1/comments/{0}",
  },

  assessment: {
    getPA: serviceAPINew + "/v2/pa/assessments/{0}/all",
  },
  healthPlan: {
    hp: serviceAPI + "/v4/hp/{0}/latest",
    category: serviceAPI + "/v4/category",
    // getPatientHealthPlan: serviceAPI + "/api/v2/health-plan/{0}",
    // getPatientHealthPlan: serviceAPI + "/api/v1/hp/{0}",
    getPatientHealthPlan:
      serviceAPI + "/api/v1/hp/list/{0}?pageNo=1&pageSize=2",

    //get latest hea;thplan
    fetchLatestHP: serviceAPI + "/api/v3/hp/{0}/latest",
    fetchHpById: serviceAPI + "/api/v3/hp/{0}/{1}",
    //hea;thplan in list list
    updatePatientHealthPlan: serviceAPI + "/api/v2/health-plan/{0}",
    getPatientGoals: serviceAPI + "/api/v2/goals/{0}/short-term?page={1}",
    createPatientGoal: serviceAPI + "/api/v2/goals",
    updatePatientGoal: serviceAPI + "/api/v2/goals/{0}",
    getItems: serviceAPI + "/api/v2/health-plan/master?page={0}",
    getOptions: serviceAPI + "/api/v2/health-plan/master/options?page={0}",
    getGoals: serviceAPI + "/api/v2/goals/master?page={0}",
    /////

    healthTemplate: serviceAPI + "/api/v2/health-plan/template",
    healthGoalTemplate: serviceAPI + "/api/v2/goals/template",
    UpdatehealthTemplate: serviceAPI + "/api/v2/health-plan/template/{0}",
    UpdatehealthGoalTemplate: serviceAPI + "/api/v2/goals/template/{0}",
    levelUp: serviceAPI + "/api/v2/health-plan/{0}/level",
    ///template----emplate
    updateTemplate: serviceAPI + "/api/v1/hp/template/{0}?nameUpdated={1}",
    template: serviceAPI + "/api/v1/hp/template",
    templatebyId: serviceAPI + "/api/v1/hp/template/{0}",
    searchTemplate: serviceAPI + "/api/v1/hp/template/search/{0}?query={1}",

    ///////////////////////categoey
    getCategory: serviceAPI + "/api/v1/category",
    createCategory: serviceAPI + "/api/v1/category",
    //items
    getItemsByCategory: serviceAPI + "/api/v1/item/category/{0}",
    createItem: serviceAPI + "/api/v1/item",
    updateItem: serviceAPI + "/api/v1/item/{0}",
    getIitemById: serviceAPI + "/api/v1/item/{0}",
    searchItem: serviceAPI + "/api/v1/item/search?query={0}",
    //option serach
    getOptionsByName: serviceAPI + "/api/v1/option/search/{0}?query={1}",

    ///options
    createOption: serviceAPI + "/api/v1/option",
    // fetchOptions:serviceAPI+"/api/v1/option/{0}",
    fetchOptions: serviceAPI + "/api/v1/option?pageNo={0}&pageSize=30",
    updateOption: serviceAPI + "/api/v1/option/{0}",

    //halthlan craeete
    createHp: serviceAPI + "/api/v1/hp/{0}",
    updateHp: serviceAPI + "/api/v1/hp/{0}/{1}",
  },
  imageUpload: {
    //post
    image: serviceAPI + "/api/v1/option/file/upload",
  },

  triviaMessage: {
    get: serviceAPI + "/statemachine/scheduler/dynamic/messages/list",
    new: serviceAPI + "/statemachine/scheduler/dynamic/message/create",
    update: serviceAPI + "/statemachine/scheduler/dynamic/message/{0}/update",
  },

  tasksReport: {
    get: serviceAPI + '/api/v2/tasks/report?where={"patientId":{0}}',
  },

  healthGoalsDashboard: {
    get: serviceAPI + "/api/v2/goals/master?page={0}",
    new: serviceAPI + "/api/v2/goals/master",
    update: serviceAPI + "/api/v2/goals/master/{0}",
  },

  healthTasksDashboard: {
    get: serviceAPI + "/api/v2/tasks/master?page={0}",
    new: serviceAPI + "/api/v2/tasks/master",
    update: serviceAPI + "/api/v2/tasks/master/{0}",
  },

  medicineOrders: {
    get: serviceAPI + "/api/v1/medicine/orders?page={0}",
    update: serviceAPI + "/api/v1/medicine/orders/{0}",
  },

  escalation: {
    getAll:
      serviceAPI + '/api/v1/vitals/escalations?page={0}&sort=[("_id",-1)]',
    update: serviceAPI + "/api/v1/vitals/escalations/{0}",
    updateStatus: serviceAPI + "/api/v1/vitals/escalations/status/update",
  },

  newAppFeatureDashboard: {
    get: serviceAPI + "/api/v1/release/version/features",
    new: serviceAPI + "/api/v1/release/version/features",
    update: serviceAPI + "/api/v1/release/version/features/{0}",
  },

  fileService: {
    new: serviceAPI + "/api/v1/file",
    get: serviceAPI + "/api/v1/file/{0}",
  },

  healthPlanDashboard: {
    get: serviceAPI + "/api/v2/health-plan/master?page={0}",
    getOptions: serviceAPI + "/api/v2/health-plan/master/options?page={0}",
    newOption: serviceAPI + "/api/v2/health-plan/master/options",
    updateOption: serviceAPI + "/api/v2/health-plan/master/options/{0}",
    new: serviceAPI + "/api/v2/health-plan/master",
    update: serviceAPI + "/api/v2/health-plan/master/{0}",
    csvUpload: serviceAPI + "/api/v2/health-plan/master/upload",
  },
  bridgeEmployee: {
    getAll: baseAPI + "/employees/all/profile",
    getById: baseAPI + "/employees/{0}",
    activate: baseAPI + "/employees/{0}/activate",
    deactivate: baseAPI + "/employees/{0}/deactivate",
    add: baseAPI + "/employees/new",
  },
  event: {
    eventLog: serviceAPI + "/v1/events",
  },
  prescription: {
    discard: serviceAPINew + "/api/v1/prescription/deletedraft/{0}",
    fetch:
      serviceAPINew +
      "/api/v1/prescription/Tracking/{0}?userId={1}&userName={2}",
    draft: serviceAPINew + "/api/v1/prescription/draft/{0}",
    update: serviceAPINew + "/api/v1/prescription/update",
    add: serviceAPINew + "/api/v1/prescription/create",
    template: {
      instruction: {
        getAll: serviceAPINew + "/api/v1/prescription/instructions",
        getById: serviceAPINew + "/api/v1/prescription/instructions/{0}",
      },
      investigations: {
        getAll: serviceAPINew + "/api/v1/prescription/investigations",
        getById: serviceAPINew + "/api/v1/prescription/investigations/{0}",
      },
    },
    searchByAbbrLabtest:
      serviceAPI + "/api/v1/dt/labtest/searchByAbbr?queryString={0}",
    searchByAbbrSubLabtest:
      serviceAPI + "/api/v1/dt/labtestwithsubtest/searchByAbbr?queryString={0}",
    getLabtestInfo: serviceAPI + "/api/v1/dt/labtest/getby/{0}",
    latestPrescription: serviceAPI + "/api/v1/prescription/{0}",
  },
  language: {
    get: baseAPI + "/language",
    update: baseAPI + "/patients/{0}/update/language?language={1}",
  },

  followUp: {
    fetchLatest: baseAPI + "/followup/latest/{0}",
    add: baseAPI + "/followup",
    get: serviceAPI + "/api/v1/followup/{0}?pageNumber={1}&pageSize={2}",
    new: serviceAPI + "/api/v1/followup",
    update: serviceAPI + "/api/v1/followup/{0}",
    primaryDoctorById: serviceAPI + "/api/v1/followup/doctor/{0}",
    // doctorNameById: baseAPI + "/calls/doctor/{0}",
    doctorNameById: baseAPI + "/docprofile/doctor/{0}",
    count: serviceAPI + "/api/v1/followup/count/{0}",
  },
  events: {
    log: serviceAPINew + "/v1/events",
  },
  family: {
    getMinorMember: serviceAPI + "/v1/family/getminormembers",
  },
  vaccine: {
    getAllVaccine: serviceAPI + "/api/v1/vaccines",
  },
  vital: {
    latestVital: serviceAPI + "/api/v2/vitals/{0}/latest",
  },
  navigoDashboard: {
    allAppointments:
      serviceAPI + "/api/v2/navigo/appointment/patient/{0}?serviceType={1}",
    allWebinars: strapiAPI + "/pfizer-contents/freemium",
  },
  doseDetails: {
    getDrugSchedule: serviceAPI + "/api/v1/nv/dose/upcomingdosedate?pid={0}",
  },
};

export default APIConfig;
